import React, { useState } from "react";
import Navbar from "./Navbar";
import vid from "./Media/Beige New Product Mockup Now Available Facebook Post.png";
import saas from "./Media/Pexels.png";
import blob from "./Media/blob.svg";
import lending from "./Media/pexels-antoni-shkraba-7163354.jpg";
import EastIcon from "@mui/icons-material/East";
import { Link } from "react-router-dom";
import {
  AppShortcut,
  BuildOutlined,
  Business,
  CalculateOutlined,
  EmojiTransportation,
  HomeOutlined,
} from "@mui/icons-material";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const WhyMywagepay = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <div className="bg-[rgba(246, 232, 200)]">
      <Navbar />
      <div className="flex flex-col h-4/5 mt-14 max-lg:mt-0">
        <div className=" flex h-[30rem] my-10  w-full max-lg:h-1/2  max-lg:w-full ">
          <div className=" flex w-3/4 justify-evenly mx-auto h-full max-lg:w-full max-lg:flex-col">
            <div className=" h-4/5 w-2/5 ml-0 max-lg:px-4 flex flex-col justify-evenly max-lg:mt-10 max-lg:w-full max-lg:h-3/5 my-auto">
              <h1 className="font-base text-5xl mb-4  max-lg:text-3xl max-lg:text-center font-serif">
                Building credit products has never been easier
              </h1>
              <h6 className=" text-left text-lg font-base  max-lg:mt-4 max-lg:text-base w-full leading-8 max-lg:w-full ">
                Only MyWagepay's white-label credit platform gives companies
                everything they need to build, embed or enhance credit products.
              </h6>
              <div className="w-full flex justify-start max-lg:justify-center">
                <Link
                  to="https://calendly.com/mywagepay/demo-meeting
"
                >
                  <button className=" hover:bg-transparent hover:border-2 hover:border-wageyellow  border-1 rounded-xl bg-wageyellow  w-48 h-12 mt-6 ">
                    Schedule a demo
                    <EastIcon
                      style={{ marginLeft: "1rem" }}
                      className="animate-pulse bg-transparent"
                    />
                  </button>
                </Link>
              </div>
            </div>
            <div className="h-full w-1/2  mt-0 p-4  max-lg:px-4 flex flex-col items-center max-lg:mt-10 max-lg:w-11/12 mx-auto  max-lg:h-3/4">
              <img src={vid} alt="..." className="h-full" />
            </div>
          </div>
        </div>
        <div className="w-4/5 mx-auto mt-8 max-lg:w-11/12">
          <div className="w-full ">
            <h2 className="text-4xl text-center max-lg:text-2xl max-lg:mb-2">
              Your command center for all things credit
            </h2>
            <h6 className="text-center text-lg font-normal w-4/5 mx-auto max-lg:w-full max-lg:text-justify max-lg:text-base">
              An all round lending infrastructure for fintechs and software
              companies and easy credit access for the businesses they serve.
            </h6>
          </div>
          <div className="flex w-11/12 justify-between mx-auto max-lg:grid grid-cols-1">
            <div className="border-2 w-80  h-80 rounded-lg bg-wageyellow/30 flex flex-col justify-evenly items-center my-5 p-4 max-lg:w-11/12 max-lg:mx-auto">
              <h2 className="text-slate-800 font-semibold text-xl text-center  bg-transparent h-10">
                Embedded
              </h2>
              <h6 className="text-base font-medium text-center  bg-transparent h-20">
                Your command center for embeddable credit
              </h6>
              <h6 className="font-normal  text-center  bg-transparent h-44">
                From application to decisioning and underwriting - Mywagepay
                provides the tools you need to build and embed credit products.
              </h6>
            </div>
            <div className="border-2 w-80  h-80 rounded-lg bg-wageyellow/30 flex flex-col justify-evenly items-center my-5 p-4 max-lg:w-11/12 max-lg:mx-auto">
              <h2 className="text-slate-800 font-semibold text-xl text-center  bg-transparent h-10">
                Score checking system
              </h2>
              <h6 className="text-base font-medium text-center  bg-transparent h-20">
                Smarter lending starts with better decisioning
              </h6>
              <h6 className="font-normal  text-center  bg-transparent h-44">
                Our platform utilizes advanced decisioning tools for smarter,
                more informed lending. Harness the power of data to optimize
                loan processes, ensuring efficiency and reliability for your
                business.
              </h6>
            </div>

            <div className="border-2 w-80  h-80 rounded-lg bg-wageyellow/30 flex flex-col justify-evenly items-center my-5 p-4 max-lg:w-11/12 max-lg:mx-auto">
              <h2 className="text-slate-800 font-semibold text-xl text-center  bg-transparent h-10">
                Capital
              </h2>
              <h6 className="text-base font-medium text-center  bg-transparent h-20">
                Specialized lenders, faster access to capital
              </h6>
              <h6 className="font-normal  text-center  bg-transparent h-44">
                Access business lending with ease through MyWagePay. Our
                platform provides tailored capital solutions, streamlining the
                process for your convenience. Experience swift funding options
                to fuel your business growth effectively.
              </h6>
            </div>
          </div>
        </div>
        <ScrollTrigger
          onEnter={() => setCounterOn(true)}
          onExit={() => setCounterOn(false)}
        >
          <div className="flex justify-evenly w-11/12 mx-auto h-44 my-10 max-lg:flex-col max-lg:h-80">
            <div className="flex flex-col justify-evenly items-center h-40">
              <h6 className="text-center font-semibold text-2xl">
                {counterOn && (
                  <CountUp start={0} end={15} duration={5} delay={0} />
                )}{" "}
                +
              </h6>
              <h6 className="text-center text-lg">Embedded partners</h6>
            </div>
            <div className="flex flex-col justify-evenly items-center h-40 border-l border-wageyellow pl-5 max-lg:pl-0">
              <h6 className="text-center font-semibold text-2xl">
                {counterOn && (
                  <CountUp start={5570} end={5580} duration={5} delay={0} />
                )}
              </h6>
              <h6 className="text-center text-lg">Registered SMEs</h6>
            </div>
            <div className="flex flex-col justify-evenly items-center h-40  border-l border-wageyellow pl-5 max-lg:pl-0">
              <h6 className="text-center font-semibold text-2xl">
                KES.{" "}
                {counterOn && (
                  <CountUp
                    start={299999000}
                    end={300000000}
                    duration={5}
                    delay={0}
                  />
                )}
                +
              </h6>
              <h6 className="text-center text-lg">Loans distributed</h6>
            </div>
          </div>
        </ScrollTrigger>

        <div className=" w-3/4 mx-auto mb-10 max-lg:mx-0 max-lg:w-full">
          <div className=" mt-20 mx-auto h-fit  max-lg:w-11/12">
            <h2 className="text-3xl w-full text-center my-5">
              For Accelerator companies
            </h2>
            <div className="flex w-11/12 mx-auto justify-between items-center max-lg:flex-col">
              <img
                src={saas}
                alt="..."
                className=" h-64 w-[28rem]  rounded-lg"
              />
              <div className=" h-64 w-[28rem] flex justify-evenly flex-col text-left max-lg:w-full">
                <h2 className="text-2xl ">
                  Embedded credit: Your new growth-driver
                </h2>
                <h6 className="">
                  Drive growth, boost your bottom-line, and delight your
                  customers with the capital they need to thrive. Straight from
                  your product experience.
                </h6>
              </div>
            </div>
            <div className="w-11/12 mx-auto mt-20 ">
              <h6 className="text-3xl w-full text-center my-5">
                Industries our Accelerator customers serve:
              </h6>
              <div className="grid grid-cols-2 gap-10 max-lg:grid-cols-1">
                <div className="flex  justify-between items-center">
                  <AppShortcut
                    className="border-2 bg-wageyellow/40 rounded-full p-2 text-4xl"
                    style={{ fontSize: "5rem" }}
                  />
                  <div className="w-full justify-start ml-3">
                    <h6 className="text-xl font-medium w-full text-left my-5">
                      Fintechs
                    </h6>
                    <h6 className="text-lg w-full text- my-5">
                      From salary advancements to short-term or long term loans
                      for SMEs, Mywagepay can help you build and launch credit
                      products. With our dedicated team and an api documentation
                      that is easy to follow that will have you up and running
                      in a matter of days.
                    </h6>
                  </div>
                </div>
                <div className="flex  justify-between items-center">
                  <BuildOutlined
                    className="border-2 bg-wageyellow/40 rounded-full p-2 text-4xl"
                    style={{ fontSize: "5rem" }}
                  />
                  <div className="w-full justify-start ml-3">
                    <h6 className="text-xl font-medium w-full text-left my-5">
                      Wholesalers
                    </h6>
                    <h6 className="text-lg w-full text- my-5">
                      Help your customers get the credit they need to take on
                      more projects, buy materials, purchase new equipment and
                      smooth out fluctuating cash-flows.
                    </h6>
                  </div>
                </div>
                <div className="flex  justify-between items-center">
                  <HomeOutlined
                    className="border-2 bg-wageyellow/40 rounded-full p-2 text-4xl"
                    style={{ fontSize: "5rem" }}
                  />
                  <div className="w-full justify-start ml-3">
                    <h6 className="text-xl font-medium w-full text-left my-5">
                      Unlicenced Lenders
                    </h6>
                    <h6 className="text-lg w-full text- my-5">
                      By partnering with MyWagePay,it not only grants you the
                      privilege of operating within regulatory frameworks but
                      also instills trust and assurance among your clientele
                      regarding the credibility and reliability of your
                      services.
                    </h6>
                  </div>
                </div>
                <div className="flex  justify-between items-center">
                  <EmojiTransportation
                    className="border-2 bg-wageyellow/40 rounded-full p-2 text-4xl"
                    style={{ fontSize: "5rem" }}
                  />
                  <div className="w-full justify-start ml-3">
                    <h6 className="text-xl font-medium w-full text-left my-5">
                      Trucking & Transportation
                    </h6>
                    <h6 className="text-lg w-full text- my-5">
                      Give your customers easier access to capital so they can
                      grow their fleet, invest in maintenance and repair, get
                      paid faster or expand their operations.
                    </h6>
                  </div>
                </div>
                <div className="flex  justify-between items-center">
                  <Business
                    className="border-2 bg-wageyellow/40 rounded-full p-2 text-4xl"
                    style={{ fontSize: "5rem" }}
                  />
                  <div className="w-full justify-start ml-3">
                    <h6 className="text-xl font-medium w-full text-left my-5">
                      B2B Marketplaces
                    </h6>
                    <h6 className="text-lg w-full text- my-5">
                      Facilitate your customers' access to capital, empowering
                      them to invest in marketing initiatives, expand their
                      workforce, enhance online capabilities, and scale
                      operations across both sides of the marketplace.
                    </h6>
                  </div>
                </div>
                <div className="flex  justify-between items-center">
                  <CalculateOutlined
                    className="border-2 bg-wageyellow/40 rounded-full p-2 text-4xl"
                    style={{ fontSize: "5rem" }}
                  />
                  <div className="w-full justify-start ml-3">
                    <h6 className="text-xl font-medium w-full text-left my-5">
                      Accounting
                    </h6>
                    <h6 className="text-lg w-full text- my-5">
                      Accelerate your customers' access to capital, enabling
                      them to grow their teams, fuel marketing efforts, attract
                      additional clients, or manage day-to-day operational
                      expenses more efficiently.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" mt-20 mx-auto h-fit  max-lg:w-11/12">
            <h2 className="text-3xl w-full text-center my-5">For Lenders</h2>
            <div className="flex w-11/12 mx-auto justify-between items-center max-lg:flex-col-reverse">
              <div className=" h-64 w-[28rem] flex justify-evenly flex-col text-left max-lg:w-full">
                <h2 className="text-2xl">
                  Reach more customers, make better lending decisions
                </h2>
                <h6 className="">
                  Reach qualified customers and make faster credit decisions
                  with Mywagepay’s score checking engine. Leverage your own
                  customer data and enhance it to build proprietary credit
                  models and blow away the competition.
                </h6>
              </div>
              <img
                src={lending}
                alt="..."
                className=" h-64 w-[28rem]  rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="w-full  h-80 relative bg-transparent max-lg:h-fit mb-2">
          <img src={blob} alt="..." className="max-lg:hidden" />
          <div className=" w-2/3 mx-auto top-24 bg-transparent left-64  flex justify-between items-center bottom-2 absolute max-lg:flex-col max-lg:relative max-lg:left-0 max-lg:w-11/12 max-lg:top-0">
            <div className="w-1/2 bg-transparent max-lg:w-full">
              <h1 className="text-left text-5xl my-4 font-serif bg-transparent">
                Launch and scale faster
              </h1>
              <h6 className="text-left text-xl my-4 bg-transparent">
                See how MyWagePay can help you build, embed or enhance your
                credit products so you can acquire more customers, increase
                revenue and accelerate growth.
              </h6>
            </div>
            <div className=" flex items-center justify-start max-lg:justify-center bg-transparent">
              <Link
                to="https://calendly.com/mywagepay/demo-meeting
"
                className="bg-transparent"
              >
                <button className="flex underline text-lg items-center w-fit bg-transparent leading-4 h-12 mt-6 max-lg:bg-wageyellow max-lg:rounded-lg max-lg:px-2 max-lg:no-underline">
                  Schedule a demo
                  <div className="flex items-center justify-center hover:bg-transparent hover:border-2 hover:border-wageyellow  border-1 rounded-full bg-wageyellow  w-10 h-10 ml-2">
                    <EastIcon className="animate-pulse bg-transparent" />
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyMywagepay;
