import React from "react";
import { Link } from "react-router-dom";
import logo from "./Media/logo.jpg";

const Loading = () => {
  return (
    <div className="ml-24 mt-4 max-lg:ml-4">
      <div className="border-b border-gray-300 w-full">
              <Link to="/">
        <div className="flex flex-row w-46 h-12 items-center ">
          <img src={logo} alt="Mywagepay" className="w-12 h-10 " />
          <h1 className="text-lg text-bold">MyWagePay</h1>
        </div>
      </Link>
        </div>
      <div className="w-full my-4 animate-pulse">
        <div className="w-11/12 h-44 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        <div className="w-11/12 h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        <div className="w-11/12 h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        <div className="flex">
          <div className="w-2/5  h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
          <div className=" w-2/5 h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        </div>
      </div>
      <div className="w-full my-4 animate-pulse">
        <div className="w-11/12 h-44 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        <div className="w-11/12 h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        <div className="w-11/12 h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        <div className="flex">
          <div className="w-2/5  h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
          <div className=" w-2/5 h-14 my-2 mx-auto bg-slate-200 border border-wageyellow rounded"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
