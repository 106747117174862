import React, { useState } from "react";
import Navbar from "../Navbar";
import { Link } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import vid from "../Media/Component 2.png";
import blob from "../Media/blob.svg";
import {CheckBoxOutlined, CheckCircle,DoneAllOutlined,  WorkspacePremiumOutlined } from "@mui/icons-material";

const CreditProfiling = () => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <Navbar modal={modal} setModal={setModal} />
      <div className=" flex  h-3/4 justify-between w-4/5 mx-auto mt-24 max-lg:h-1/2  max-lg:w-full ">
        <div className=" flex w-4/5 justify-evenly mx-auto h-full max-lg:w-full max-lg:flex-col">
          <div className=" h-4/5 w-2/5 ml-0 max-lg:px-4 flex flex-col justify-evenly max-lg:mt-10 max-lg:w-full max-lg:h-3/5 my-auto">
            <h1 className="font-base text-5xl mb-4  max-lg:text-2xl max-lg:text-left font-serif">
              Specialized lenders, faster access to capital
            </h1>
            <h6 className=" text-left text-lg font-base  max-lg:mt-4 max-lg:text-base w-full leading-8 max-lg:w-full ">
              From term loans to lines of credit and everything in between, our
              marketplace of specialized lenders across multiple industries
              gives your customers easier, faster access to capital.
            </h6>
            <div className="w-full flex justify-start max-lg:justify-center">
              <Link
                to="https://calendly.com/mywagepay/demo-meeting
"
              >
                <button className=" hover:bg-transparent hover:border-2 hover:border-wageyellow  border-1 rounded-xl bg-wageyellow  w-48 h-12 mt-6 ">
                  Schedule a demo
                  <EastIcon
                    style={{ marginLeft: "1rem" }}
                    className="animate-pulse bg-transparent"
                  />
                </button>
              </Link>
            </div>
          </div>
          <div className="h-full w-2/3  mt-0 p-4  max-lg:px-4 flex flex-col items-center max-lg:mt-10 max-lg:w-11/12 mx-auto  max-lg:h-3/4">
            <img src={vid} alt="..." className="h-full" />
          </div>
        </div>
      </div>
      <div className="mt-14 w-11/12 mx-auto">
        <h6 className="w-1/2 text-center mx-auto text-4xl h-1/2 font-normal my-3">
          Better approval rates, happier customers
        </h6>
        <h6 className="w-4/5 mx-auto text-center text-lg h-1/2 font-normal">
          Not all customers are created equal, so we work with diverse funding
          partners that specialize in each industry and truly understand their
          specific needs to deliver higher approval rates and better credit
          options.
        </h6>
        <div className="w-4/5  mx-auto mt-10 h-52 flex justify-between gap-5">
          <div className=" w-72 text-left border-l border-wageyellow h-40 pl-2">
            <h6 className="text-wageyellow text-3xl font-semibold">
              20+ credit products
            </h6>
            <h6 className="text-lg">
              From LOCs to equipment finance and everything in between
            </h6>
          </div>
          <div className=" w-72 text-left border-l border-wageyellow h-40 pl-2">
            <h6 className="text-wageyellow text-3xl font-semibold">
              $2K to $5M+ loan amounts
            </h6>
            <h6 className="text-lg">To meet every customer’s needs</h6>
          </div>
          <div className=" w-72 text-left border-l border-wageyellow h-40 pl-2">
            <h6 className="text-wageyellow text-3xl font-semibold">
              Subprime to A-rated
            </h6>
            <h6 className="text-lg">Serving a wide range of borrowers</h6>
          </div>
        </div>
      </div>
      <div className="mt-14 w-11/12 mx-auto">
        <h6 className="w-11/12 text-center mx-auto text-4xl h-1/2 font-normal my-3">
          Give your customers access to credit products they need to grow:{" "}
        </h6>

        <div className="w-4/5  mx-auto mt-10 h-52 grid grid-cols-3 gap-5">
          <h6 className="flex items-center text-lg">
            <CheckCircle style={{color:"rgb(216, 156, 16)",marginRight:"1rem",fontSize:"2rem"}}/> Line of Credit
          </h6>{" "}
          <h6 className="flex items-center text-lg">
            <CheckCircle style={{color:"rgb(216, 156, 16)",marginRight:"1rem",fontSize:"2rem"}}/> Receivable Purchase
          </h6>
          <h6 className="flex items-center text-lg">
            <CheckCircle style={{color:"rgb(216, 156, 16)",marginRight:"1rem",fontSize:"2rem"}}/> Equipment Financing
          </h6>
          <h6 className="flex items-center text-lg">
            <CheckCircle style={{color:"rgb(216, 156, 16)",marginRight:"1rem",fontSize:"2rem"}}/> Term Loan
          </h6>
          <h6 className="flex items-center text-lg">
            <CheckCircle style={{color:"rgb(216, 156, 16)",marginRight:"1rem",fontSize:"2rem"}}/> Invoice Factoring
          </h6>
          <h6 className="flex items-center text-lg">
            <CheckCircle style={{color:"rgb(216, 156, 16)",marginRight:"1rem",fontSize:"2rem"}}/> E-commerce loans
          </h6>
        </div>
      </div>
      <div className="mt-14 w-11/12 mx-auto">
        <h6 className="w-11/12 text-center mx-auto text-4xl h-1/2 font-normal my-3">
        Integrity is our commitment. Transparency is our promise.
        </h6>
        <h6 className=" text-center text-lg font-base  max-lg:mt-4 max-lg:text-base w-full leading-8 max-lg:w-full ">
        Lending solutions delivered with a human touch from partners you can trust.
            </h6>
            <div className="w-4/5  mx-auto mt-10 h-64 mb-10 flex justify-between">
          <div className="  w-80 border border-wageyellow bg-wageyellow/10 relative rounded-xl p-4">
            <CheckBoxOutlined className="bg-transparent text-wageyellow absolute -top-8 text-5xl" style={{textAlign:"center",fontSize:"4rem",width:"90%", margin:"0 auto"}}/>
            <h6 className="text-center text-slate-800 text-2xl font-semibold bg-transparent h-16 mt-5">
            Vetted Funders

            </h6>
            <h6 className="text-lg bg-transparent h-32 text-center">
            Lenders on MyWagepay's marketplace go through a qualification process to ensure ethical and fair lending practices.
            </h6>
          </div>
          <div className="  w-80 border border-wageyellow relative bg-wageyellow/10 rounded-xl p-4">
            <DoneAllOutlined className="bg-transparent text-wageyellow absolute -top-8 text-5xl" style={{textAlign:"center",fontSize:"4rem",width:"90%", margin:"0 auto"}}/>
            <h6 className="text-center text-slate-800 text-2xl font-semibold bg-transparent h-16  mt-5">
            Transparent Lending
            </h6>
            <h6 className="text-lg bg-transparent h-32 text-center">Applicants on our platform receive clear offers: no hidden fees, sketchy terms, or surprises throughout the application process.</h6>
          </div>
          <div className="  w-80 border border-wageyellow relative bg-wageyellow/10 rounded-xl p-4">
            <WorkspacePremiumOutlined className="bg-transparent text-wageyellow absolute -top-8 text-5xl" style={{textAlign:"center",fontSize:"4rem",width:"90%", margin:"0 auto"}}/>
            <h6 className="text-center text-slate-800 text-2xl font-semibold bg-transparent h-16  mt-5">
            Fair Rates
            </h6>
            <h6 className="text-lg bg-transparent h-32 text-center">Greater transparency means borrowers receive the best possible rates and terms for their situation and risk profile.</h6>
          </div>
        </div>
      </div>
      <div className="w-full  h-80 relative ">
      <img src={blob} alt="..." className="max-lg:hidden" />
      <div className=" w-2/3 mx-auto top-24 bg-transparent left-64  flex justify-between items-center  absolute max-lg:flex-col max-lg:relative max-lg:left-0 max-lg:w-11/12 max-lg:top-0">
        <div className="w-1/2 bg-transparent max-lg:w-full">
          <h1 className="text-left text-4xl my-4 font-serif">
          Match your customers to the capital they need
          </h1>
          <h6 className="text-left text-xl my-4 bg-transparent">
          See how Mywagepay can match your customers to specialized lenders that can get them faster, easier access to capital
          </h6>
        </div>
        <div className="w-1/4 bg-transparent flex items-center justify-start max-lg:justify-center">
          <Link
            to="https://calendly.com/mywagepay/demo-meeting
" className="bg-transparent"
          >
            <button className="flex underline text-lg items-center w-fit leading-4 h-12 mt-6 ">
              Schedule a demo
              <div className="flex items-center justify-center hover:bg-transparent hover:border-2 hover:border-wageyellow  border-1 rounded-full bg-wageyellow  w-10 h-10 ml-2">
                <EastIcon className="animate-pulse bg-transparent" />
              </div>
            </button>
          </Link>
        </div>
      </div>
      </div>
    </>
  );
};

export default CreditProfiling;
