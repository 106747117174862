import React from "react";
import logo from "./Media/logo.jpg";
import { Link } from "react-router-dom";
import SegmentRoundedIcon from "@mui/icons-material/SegmentRounded";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Drawer,
  Modal,
} from "@mui/material";
import { ArrowDown } from "@rsuite/icons";

const Navbar = () => {
  const [anchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [modalEl, setModalEl] = React.useState(null);

  const handleOpen = (event) => {
    setModalEl(event.currentTarget);
  };

  const handleShut = () => {
    setModalEl(null);
  };
  const [companyEl, setCompanyEl] = React.useState(null);

  const OpenCompany = Boolean(companyEl);

  const handleCompanyOpen = (event) => {
    setCompanyEl(event.currentTarget);
  };

  const handleCompanyShut = () => {
    setCompanyEl(null);
  };

  const [NewsEl, setNewsEl] = React.useState(null);

  const OpenNews = Boolean(NewsEl);

  const handleNewsOpen = (event) => {
    setNewsEl(event.currentTarget);
  };

  const handleNewsShut = () => {
    setNewsEl(null);
  };
  const Open = Boolean(modalEl);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const list = (
    <div
      className="flex flex-col mt-10 bg-transparent w-screen mx-auto justify-evenly font-bold  cursor-pointer "
      role="presentation"
    >
      <Link
        to="/whymywagepay"
        spy={true}
        smooth={true}
        offset={50}
        duration={500}
        className="hover:bg-wageyellow font-bold ml-4 rounded bg-transparent"
      >
        Why MyWagePay
      </Link>
      <Accordion
        expanded={expanded === "panel1"}
        className="bg-transparent"
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          className="bg-transparent"
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Products
        </AccordionSummary>

        <AccordionDetails>
          <div>
            <Link
              to="/embed"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
              <h6 className="p-1 bg-transparent">Embed</h6>
            </Link>

            <Link
              to="/capital"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
              <h6 className="p-1 bg-transparent">Capital</h6>
            </Link>

            <Link
              to="/profiling"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
              <h6 className="p-1 bg-transparent">Score Check</h6>
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          className="bg-transparent"
          expandIcon={<ExpandMore />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Company
        </AccordionSummary>
        <AccordionDetails>
          <div className="">
            <Link
              to="/aboutus"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
              <h6 className="p-1 bg-transparent">About us</h6>
            </Link>

            <Link
              to="/careers"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
              <h6 className="p-1 bg-transparent">Careers</h6>
            </Link>
            <Link
              to="/register"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
              <h6 className="p-1 bg-transparent">Contact us</h6>
            </Link>

            <Link
              to="profiling"
              className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
            >
             
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
      <Link
        to="/invest"
        spy={true}
        smooth={true}
        offset={50}
        duration={500}
        className="hover:bg-wageyellow h-8 flex items-center font-bold ml-4 rounded bg-transparent"
      >
        Invest
      </Link>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          className="bg-transparent"
          expandIcon={<ExpandMore />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Learn
        </AccordionSummary>
        <AccordionDetails>
          <div className="">
            <Link
              to="/news"
              className="hover:bg-wageyellow px-2 h-10 rounded border-b border-slate-400  font-normal"
            >
              <h6 className="p-1 bg-transparent">Whats new</h6>
            </Link>

            <Link
              to="/capital"
              className="hover:bg-wageyellow px-2 h-10 rounded border-b border-slate-400  font-normal"
            >
              <h6 className="p-1 bg-transparent">Blog</h6>
            </Link>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );

  return (
    <div>
      <div className=" flex flex-row items-center justify-evenly fixed  w-screen top-0 h-16 px-24 border-b border-wageyellow max-lg:justify-between max-lg:px-0">
        <Link to="/" className="flex flex-row w-46 h-12 items-center ">
          <img src={logo} alt="Mywagepay" className="w-12 h-10 m-1" />
          <h1 className="text-lg text-bold">MyWagePay</h1>
        </Link>
        <div className="lg:flex flex-row w-2/3 justify-evenly font-bold  max-lg:hidden cursor-pointer ">
          <Link
            to="/whymywagepay"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="hover:bg-wageyellow px-2  rounded  font-normal"
          >
            Why MyWagePay
          </Link>
          <div className="relative">
            <h6 onClick={handleOpen} className="text-black  font-normal">
              Products
              <ArrowDown />
            </h6>

            <Modal
              open={Open}
              onClose={handleShut}
              className=" left-0 bg-transparent border-2 border-green-800 top-10"
            >
              <div className="w-44 flex justify-evenly flex-col rounded absolute left-1/3 top-14 text-center  bg-white text-slate-600">
                <Link
                  to="/embed"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                  <h6 className="p-1 bg-transparent">Embed</h6>
                </Link>

                <Link
                  to="/capital"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                  <h6 className="p-1 bg-transparent">Capital</h6>
                </Link>

                <Link
                  to="/profiling"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                  <h6 className="p-1 bg-transparent">Score Check</h6>
                </Link>
              </div>
            </Modal>
          </div>
          <div className="relative">
            <h6 onClick={handleCompanyOpen} className="text-black  font-normal">
              Company
              <ArrowDown />
            </h6>
            <Modal
              open={OpenCompany}
              onClose={handleCompanyShut}
              className=" left-0 bg-transparent border-2 border-green-800 top-10"
            >
              <div className="w-44 flex justify-evenly flex-col rounded absolute left-1/2 top-14 text-center  bg-white text-slate-600">
                <Link
                  to="/aboutus"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                  <h6 className="p-1 bg-transparent">About us</h6>
                </Link>

                <Link
                  to="/careers"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                  <h6 className="p-1 bg-transparent">Careers</h6>
                </Link>
                <Link
                  to="/register"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                  <h6 className="p-1 bg-transparent">Contact us</h6>
                </Link>

                <Link
                  to="/profiling"
                  className="hover:bg-wageyellow px-2  rounded border-b border-slate-400  font-normal "
                >
                 
                </Link>
              </div>
            </Modal>
          </div>
          <Link
            to="/invest"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            className="hover:bg-wageyellow px-2 font-semibold  rounded "
          >
            Invest
          </Link>
          <div className="relative">
            <h6 onClick={handleNewsOpen} className="text-black  font-normal">
              Learn <ArrowDown />
            </h6>
            <Modal
              open={OpenNews}
              onClose={handleNewsShut}
              className=" left-0 bg-transparent border-2 border-green-800 top-10"
            >
              <div className="w-44 flex justify-evenly flex-col rounded absolute left-3/4 top-14 text-center  bg-white text-slate-600">
                <Link
                  to="/news"
                  className="hover:bg-wageyellow px-2 h-10 rounded border-b border-slate-400  font-normal"
                >
                  <h6 className="p-1 bg-transparent">Whats new</h6>
                </Link>

                <Link
                  to="/capital"
                  className="hover:bg-wageyellow px-2 h-10 rounded border-b border-slate-400  font-normal"
                >
                  <h6 className="p-1 bg-transparent">Blog</h6>
                </Link>
              </div>
            </Modal>
          </div>
        </div>

        <div className="max-lg:flex flex-row items-center justify-between ">
          <div className="hidden max-lg:block mr-4">
            <SegmentRoundedIcon aria-describedby={id} onClick={toggleDrawer} />
          </div>
          <Link to="/Accounts">
            <button className="text-white border-1 max-lg:hidden rounded-xl bg-wageyellow w-44 h-12 max-lg:w-24">
              Get Started
            </button>
          </Link>
        </div>
      </div>
      <Drawer open={openDrawer} anchor="top" onClose={toggleDrawer}>
        {list}
      </Drawer>
    </div>
  );
};

export default Navbar;
