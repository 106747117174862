import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Loading from "./Loading";
import CreditProfiling from "./Products/CreditProfiling";
import WhyMywagepay from "./WhyMywagepay";
import News from "./News/News";
const PrivacyPolicy = React.lazy(()=>import("./PrivacyPolicy"))
const Capital = React.lazy(()=>import("./Products/Capital"))
const Embedded = React.lazy(()=>import("./Products/Embedded"))
const NewsDets = React.lazy(() => import("./News/NewsDetails"));
const Home = React.lazy(() => import("./LandingPage/Home"));
const Accounts = React.lazy(() => import("./Accounts"));
const Register = React.lazy(() => import("./Register"));
const Faq = React.lazy(() => import("./Faq"));
const Aboutus = React.lazy(() => import("./Aboutus"));
const Blogpage = React.lazy(() => import("./Blog/Blogpage"));
const BlogView = React.lazy(() => import("./Blog/Blogview"));
const Terms = React.lazy(() => import("./Terms"));
const Footer = React.lazy(() => import("./Footer"));
const Careers = React.lazy(()=> import("./Careers"))
const Invest = React.lazy(()=>import("./Invest"))

function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Accounts" element={<Accounts />} />
          <Route path="/register" element={<Register />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/profiling" element={<Capital />} />
          <Route path="/embed" element={<Embedded />} />
          <Route path="/capital" element={<CreditProfiling/>}/>
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/invest" element={<Invest/>}/>
          <Route path="/news" element={<News/>}/>
          <Route path="/loading" element={<Loading />} />
          <Route path="/blog" element={<Blogpage />} />
          <Route path="/blogview/:slug/:category" element={<BlogView />} />
          <Route path="/termsandconditions" element={<Terms />} />
          <Route path="newspost/:slug" element={<NewsDets />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/whymywagepay" element={<WhyMywagepay/>}/>
          <Route path="/Careers" element={<Careers/>}/>
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
